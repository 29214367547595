import React from "react"
import { graphql } from 'gatsby'
import "../components/valuation/valuation.scss"
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelp"
import Form from "../components/forms/reach-us-form";
import Breadcrumbs from "../components/MgServices/Breadcrumb/static-breadcrumb"
import SEO from "../components/seo"

import Bg from "../images/valuation-banner-1600.jpg"

const ReachUsDetails = (props) => {
    const data = props.data.strapiOffices
	var meta_desc = "How to reach Martyn Gerrard’s "+data.Name+" office? Find contact & address details of your nearest award-winning office with local property knowledge second to none."
	return (
		<div className="content book-a-valuation">
		<div className="reach-us-page-template">
	        <HeaderTwo />
			<SEO location={props.location} title={'Contact '+data.Name} description={meta_desc} />
      		<div className="event-section">
      			<div className="reach-us-pages">
	      			<div className="valuation-banner">
		              <picture>
		                <source media="(min-width:1699px)" srcset={Bg} />
		                <source media="(min-width:992px)" srcset={Bg} />
		                <source media="(min-width:768px)" srcset={Bg} />
		                <img loading="lazy" className="bg-img" src={Bg} alt="BannerImg" />
		              </picture>
	            	</div>
            		<div className="event-wrap main-event-wrap">
              		<Breadcrumbs id="5ff320ac413d2f077a01064b" parent="contact" tag="market-property" contact_parent={data.Name} page="Reach Us" />
            		<div class="event-title"><h1>Contact {data.Name}</h1></div>
              			<Form to_email_id={data.Email} name={data.Name} general={data.Usearea_General} sales={''/*data.Usearea_Sales*/} letting={data.Usearea_Lettings} source="office"/>
            		</div>
      			</div>
      		</div>
	        <CanHelp />
	        <Footer />
		</div>
		</div>
	)
}


export default ReachUsDetails

export const pageQuery = graphql`
  query ReachUsQuery($slug: String!) {
      strapiOffices(URL: {eq: $slug}) {
	    Address
	    Email
	    Latitude
	    Longtitude
	    Meta_Description
	    Meta_Title
	    Name
	    URL
	    Phone
		Usearea_General
		Usearea_Lettings
		Usearea_Sales
      }

    }
`
